import "./src/styles/global.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = JSON.parse(process.env.GATSBY_FIREBASE_CONFIG || "null");

// Initialize Firebase
if (firebaseConfig) {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
}
